import {
	activateSubscription,
	cancelSubscription,
	getActiveChurnLandingPageURL,
	getAddress,
	getSubscription,
	initRecharge,
	listCharges,
	listOrders,
	listSubscriptions,
	loginWithShopifyStorefront,
	updateAddress,
	updateSubscription
} from '@rechargeapps/storefront-client'
import type {
	IsoDateString,
	OrderStatus,
	Property,
	Session as RechargeSession,
	SubscriptionListParams,
	UpdateAddressRequest
} from '@rechargeapps/storefront-client'
import * as Sentry from '@sentry/nextjs'

import type { FormAddress } from '@lib/types/profile-pages'

export const initializeRecharge = (accessToken: string | undefined) => {
	// console.log("Initializing Recharge...")
	initRecharge({
		storeIdentifier: process.env.NEXT_PUBLIC_STORE_DOMAIN!,
		storefrontAccessToken: process.env.NEXT_PUBLIC_RECHARGE_STOREFRONT_ACCESS_TOKEN!,
		loginRetryFn: async () => {
			console.log('Retrying Recharge login...')
			return loginWithShopify(accessToken)
		}
	})
}

/* Login with Shopify customer account */
export const loginWithShopify = async (accessToken: string | undefined) => {
	if (!accessToken) return undefined
	const storefrontAccessToken = process.env.NEXT_PUBLIC_STOREFRONT_API_ACCESS_TOKEN!
	try {
		const newSession = await loginWithShopifyStorefront(storefrontAccessToken, accessToken)
		return newSession ?? undefined
	} catch (error) {
		console.log('Unable to login with Shopify: ', error)
		Sentry.captureException(error) // Capture the exception
		return undefined
	}
}

/* Fetch active churn landing page URL */
export const fetchActiveChurnLandingPageURL = async (
	session: RechargeSession,
	subscriptionId: string,
	redirecturl: string
) => {
	// console.log("Fetching landing page URL...")
	if (!session) return undefined
	try {
		const url = await getActiveChurnLandingPageURL(session, subscriptionId, redirecturl)
		return url ?? undefined
	} catch (error) {
		console.log('Unable to retrieve URL: ', error)
		console.log('session:', JSON.stringify(session))
		Sentry.captureException(error) // Capture the exception
		return undefined
	}
}

/* Get subscriptions */
export const getSubscriptions = async (session: RechargeSession, withAddress = false) => {
	// console.log("Fetching subscriptions...")
	if (!session) return undefined
	try {
		const query: SubscriptionListParams = {
			sort_by: 'id-desc',
			limit: 25
		}
		if (withAddress) {
			query.include = ['address']
		}
		const subscriptions = await listSubscriptions(session, query)
		return subscriptions.subscriptions ?? undefined
	} catch (error) {
		console.log('Unable to retrieve subscriptions: ', error)
		console.log('session:', JSON.stringify(session))
		Sentry.captureException(error) // Capture the exception
		return undefined
	}
}

/* Get first active subscriptions */
export const getActiveSubscription = async (session: RechargeSession) => {
	// console.log("Fetching subscriptions...")
	if (!session) return undefined
	try {
		const subscriptions = await listSubscriptions(session, {
			sort_by: 'id-desc',
			status: 'active',
			limit: 1
		})
		return subscriptions.subscriptions && subscriptions.subscriptions.length
			? subscriptions.subscriptions[0]
			: undefined
	} catch (error) {
		console.log('Unable to retrieve subscriptions: ', error)
		console.log('session:', JSON.stringify(session))
		Sentry.captureException(error) // Capture the exception
		return undefined
	}
}

/* Get first active subscriptions */
export const getRechargeSubscription = async (session: RechargeSession, id: number | string) => {
	// console.log("Fetching subscriptions...")
	if (!session) return undefined
	try {
		const subscription = await getSubscription(session, id)
		return subscription
	} catch (error) {
		console.log('Unable to retrieve subscriptions: ', error)
		console.log('session:', JSON.stringify(session))
		Sentry.captureException(error) // Capture the exception
		return undefined
	}
}

/* Get Address */
export const getRechargeAddress = async (session: RechargeSession, addressId: string | number) => {
	// console.log("Fetching address...")
	if (!session) return undefined
	try {
		const address = await getAddress(session, addressId)
		return address ?? undefined
	} catch (error) {
		console.log('Unable to retrieve address: ', error)
		console.log('session:', JSON.stringify(session))
		Sentry.captureException(error) // Capture the exception
		return undefined
	}
}

export const cancelRechargeSubscription = async (
	session: RechargeSession,
	subscriptionId: string | number,
	cancellationReason: string
) => {
	if (!session) return undefined
	try {
		const subscription = await cancelSubscription(session, subscriptionId, {
			cancellation_reason: cancellationReason
		})
		return subscription
	} catch (error) {
		console.log('Unable to cancel the subscription: ', error)
		console.log('session:', JSON.stringify(session))
		Sentry.captureException(error) // Capture the exception
		return undefined
	}
}

export const activeRechargeSubscription = async (
	session: RechargeSession,
	subscriptionId: string | number
) => {
	if (!session) return undefined
	try {
		const subscription = await activateSubscription(session, subscriptionId)
		return subscription
	} catch (error) {
		console.log('Unable to activate the subscription: ', error)
		console.log('session:', JSON.stringify(session))
		Sentry.captureException(error) // Capture the exception
		return undefined
	}
}

export const updateRechargeAddress = async (
	session: RechargeSession,
	addressId: string | number,
	newAddress: FormAddress
) => {
	if (!session) return undefined
	try {
		const requestAddress: UpdateAddressRequest = {
			address1: newAddress.address1,
			address2: newAddress.address2,
			city: newAddress.city,
			company: newAddress.company,
			country_code: newAddress.country_code,
			first_name: newAddress.first_name,
			last_name: newAddress.last_name,
			phone: newAddress.phone,
			province: newAddress.province,
			zip: newAddress.zip ?? undefined
		}
		const updatedAddress = await updateAddress(session, addressId, requestAddress)
		return updatedAddress
	} catch (error) {
		console.log('Unable to update the address: ', error)
		console.log('session:', JSON.stringify(session))
		Sentry.captureException(error) // Capture the exception
		return undefined
	}
}

export const getSubscriptionOrders = async (
	session: RechargeSession,
	subscriptionId: string | number,
	afterCreatedAt?: IsoDateString,
	status?: OrderStatus
) => {
	if (!session) return undefined
	try {
		const orders = await listOrders(session, {
			purchase_item_id: subscriptionId,
			created_at_min: afterCreatedAt ?? undefined,
			status: status ?? undefined
		})
		return orders.orders ?? undefined
	} catch (error) {
		console.log('Unable to retrieve orders: ', error)
		console.log('session:', JSON.stringify(session))
		Sentry.captureException(error) // Capture the exception
		return undefined
	}
}

export const getLastCharge = async (session: RechargeSession, subscriptionId: string | number) => {
	if (!session) return undefined
	try {
		const charges = await listCharges(session, {
			status: ['success'],
			purchase_item_id: subscriptionId,
			sort_by: 'scheduled_at-desc',
			limit: 1
		})
		return charges.charges && charges.charges.length ? charges.charges[0] : undefined
	} catch (error) {
		console.log('Unable to retrieve orders: ', error)
		console.log('session:', JSON.stringify(session))
		Sentry.captureException(error) // Capture the exception
		return undefined
	}
}

export const addPropertiesToSubscription = async (
	session: RechargeSession,
	subscriptionId: string | number,
	properties: Property[]
) => {
	if (!session) return undefined
	try {
		const subscription = await getSubscription(session, subscriptionId)
		const newSubscription = await updateSubscription(session, subscriptionId, {
			properties: [...subscription.properties, ...properties]
		})
		return newSubscription
	} catch (error) {
		console.log('Unable to add properties to subscription: ', error)
		console.log('session:', JSON.stringify(session))
		Sentry.captureException(error) // Capture the exception
		return undefined
	}
}
